import {useAppDispatch} from "@root/Hooks";
import React, {useEffect} from "react";
import {SberidSDKSuccessResult} from "@sberid/js-sdk";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import Actions from "@root/Redux/actions";
import {getAbsPath} from "@root/Utils/getAbsPath/getAbsPath";
import ContentController from "../Controllers/ContentController";
import {Api} from "@root/Types";
import useLastPathStorage from "@root/Hooks/useSaveLastPath/Hooks/useLastPathStorage/useLastPathStorage";
import isLastPathValid from "@root/Utils/LastPath/isLastPathValid";

type SberIDExchangeProps = {
    data: Partial<SberidSDKSuccessResult>;
    vendor: Api.Auth.VendorType;
}

const SberIDExchange = (props: SberIDExchangeProps) => {
    const {data} = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [lastPath] = useLastPathStorage();

    const redirect = () => {
        if (isLastPathValid({lastPath})) history.push(lastPath);
        else history.push(routes.lk.projects);
    };

    useEffect(() => {
        const path = props.vendor === "sber_id"
            ? getAbsPath(routes.auth.sberIDAuth())
            : getAbsPath(routes.auth.sbiAuth());
        if (!path) return;
        const onSuccess = () => {
            redirect();
        };
        dispatch(Actions.Auth.vendorAuth({
            auth_code: data.code,
            state: data.state,
            redirect_uri: path,
            vendor: props.vendor,
            onSuccess
        }));
    }, []);
    
    return (
        <ContentController />
    );
};

export default SberIDExchange;
