import rootSagaCreator from "../rootSagaCreator";
import GetEntitiesSaga from "./GetEntitiesSaga";
import GetLastDocumentsSaga from "./GetLastDocumentsSaga";
import GetStatistics from "./GetStatistics";
import GetTariffs from "./GetTariffs";

export default function* rootSaga() {
    yield rootSagaCreator([
        GetEntitiesSaga,
        GetStatistics,
        GetLastDocumentsSaga,
        GetTariffs
    ], "COMMON");
}
