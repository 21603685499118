import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iState, iActions} from "./types";
import getRecoveryStatus from "./Utils/getRecoveryStatus";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
} from "@root/Utils/Redux/getRequestState";

const initialState:iState.Value = {
    entities: getFullState(),
    scroll: null,
    recoveryStatus: getRecoveryStatus(),
    documentsTableConfig: null,
    projectsTableConfig: null,
    templatesTableConfig: null,
    statistics: getFullState(),
    lastDocuments: getFullState(),
    deletedDocumentsColumns: null,
    tariffs: getFullState(),
};

const Slice = createSlice({
    initialState,
    name: "Common",
    reducers: {
        getEntities: (state) => {
            requestStart(state.entities);
        },
        _getEntitiesSuccess: (state, action: PayloadAction<iActions._getEntitiesSuccess>) => {
            requestSuccess(state.entities, action.payload);
        },
        _getEntitiesError: (state) => {
            requestError(state.entities);
        },
        stopGetEntities: (state) => {
            return state;
        },
        setScrollPosition: (state, action: PayloadAction<iActions.setScrollPosition>) => {
            state.scroll = action.payload;
        },
        setRecoveryStatus: (state, action: PayloadAction<iActions.setRecoveryStatus>) => {
            state.recoveryStatus = action.payload;
        },
        setTableConfig: (state, action: PayloadAction<iActions.setTableConfig>) => {
            if (action.payload.table === "documents") {
                state.documentsTableConfig = {
                    ...state.documentsTableConfig,
                    ...action.payload.config
                };
                return;
            }
            if (action.payload.table === "projects") {
                state.projectsTableConfig = action.payload.config;
                return;
            }
            if (action.payload.table === "templates") {
                state.templatesTableConfig = action.payload.config;
                return;
            }
        },
        eraseTableConfig: (state, action: PayloadAction<iActions.eraseTableConfig>) => {
            if (action.payload === "documents") {
                state.documentsTableConfig = null;
                return;
            }
            if (action.payload === "projects") {
                state.projectsTableConfig = null;
                return;
            }
            if (action.payload === "templates") {
                state.templatesTableConfig = null;
                return;
            }
            if (action.payload === "all") {
                state.documentsTableConfig = null;
                return;
            }
        },
        getStatistics: (state) => {
            requestStart(state.statistics);
        },
        _getStatisticsSuccess: (state, action: PayloadAction<iActions._getStatisticsSuccess>) => {
            requestSuccess(state.statistics, action.payload);
        },
        _getStatisticsError: (state) => {
            requestError(state.statistics);
        },
        stopGetStatistics: (state) => {
            return state;
        },
        getLastDocuments: (state) => {
            requestStart(state.lastDocuments);
        },
        _getLastDocumentsSuccess: (state, action: PayloadAction<iActions._getLastDocumentsSuccess>) => {
            requestSuccess(state.lastDocuments, action.payload);
        },
        _getLastDocumentsError: (state) => {
            requestError(state.lastDocuments);
        },
        stopGetLastDocuments: (state) => {
            return state;
        },
        setDocumentsDeletedColumns: (state, action: PayloadAction<string[]>) => {
            state.deletedDocumentsColumns = action.payload;
        },
        getTariffs: (state) => {
            requestStart(state.tariffs);
        },
        _getTariffsSuccess: (state, action: PayloadAction<iActions._getTariffsSuccess>) => {
            requestSuccess(state.tariffs, action.payload);
        },
        _getTariffsError: (state) => {
            requestError(state.tariffs);
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
