import {combineReducers} from "redux";
import User from "./User/UserRedux";
import Auth from "./Auth/AuthRedux";
import Projects from "./Projects/ProjectsRedux";
import Project from "./Project/ProjectRedux";
import Document from "./Document/DocumentRedux";
import Anonymize from "./Anonymize/Anonymize";
import Common from "./Common/CommonRedux";
import HTML from "./HTML/HTML";
import Templates from "./Templates/TemplatesRedux";
import Tariff from "./Tariff/TariffRedux";

export default combineReducers({
    User,
    Auth,
    Projects,
    Project,
    Document,
    Anonymize,
    Common,
    HTML,
    Templates,
    Tariff
});
