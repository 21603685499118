import * as React from "react";

const Redact = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill="#ECF6EE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.9974 28.4429C20.9974 28.0287 21.3332 27.6929 21.7474 27.6929H29C29.4142 27.6929 29.75 28.0287 29.75 28.4429C29.75 28.8571 29.4142 29.1929 29 29.1929H21.7474C21.3332 29.1929 20.9974 28.8571 20.9974 28.4429Z" fill="#00CA81"/>
            <path d="M20.78 11.7948C21.5557 10.8678 22.95 10.7319 23.8962 11.4917C23.9485 11.533 25.6295 12.8388 25.6295 12.8388C26.669 13.4672 26.992 14.8031 26.3494 15.8226C26.3153 15.8772 16.8119 27.7645 16.8119 27.7645C16.4958 28.1589 16.0158 28.3918 15.5029 28.3973L11.8635 28.443L11.0435 24.9723C10.9287 24.4843 11.0435 23.9718 11.3597 23.5773L20.78 11.7948Z" stroke="#00CA81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.426 13.544C18.6783 13.2154 19.1491 13.1536 19.4776 13.4059L24.9299 17.5931C25.2584 17.8454 25.3202 18.3162 25.0679 18.6447C24.8156 18.9732 24.3448 19.035 24.0163 18.7827L18.564 14.5956C18.2355 14.3433 18.1737 13.8725 18.426 13.544Z" fill="#00CA81"/>
        </svg>
    );
};

export default Redact;
