import Actions from "@actions";
import {takeLatest, call, put, delay} from "typed-redux-saga";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@root/Api";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {iActions} from "@root/Redux/Tariff/types";

const unSubscribe = function* (action: PayloadAction<iActions.unSubscribe>) {
    const {onError, onSuccess} = action.payload;
    const notification = useNotification();
    
    try {
        yield delay(1500);
        // yield* call(Api.Tariff.unSubscribe);
        notification({
            type: "info",
            message: "Подписка успешно отменена"
        });
        onSuccess && onSuccess();
        yield* put(Actions.Tariff._unSubscribeSuccess());
    } catch (error) {
        notification({
            type: "error",
            message: "При отмене подписки произошла ошибка"
        });
        onError && onError();
        yield* put(Actions.Tariff._unSubscribeError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.unSubscribe, unSubscribe);
}
