import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iState, iActions} from "./types";
import {
    getFullState,
    requestStart, 
    requestError,
    requestSuccess,
    getShortState
} from "@root/Utils/Redux/getRequestState";

const initialState: iState.Value = {
    myTariff: getFullState(),
    unSubscribe: getShortState(),
    addUsers: getShortState(),
    addPages: getShortState()
};

const Slice = createSlice({
    initialState,
    name: "Tariff",
    reducers: {
        myTariff: (state, action: PayloadAction<iActions.myTariff>) => {
            requestStart(state.myTariff);
        },
        _myTariffSuccess: (state, action:PayloadAction<iActions._myTariffSuccess>) => {
            requestSuccess(state.myTariff, action.payload);
        },
        _myTariffError: (state) => {
            requestError(state.myTariff);
        },
        unSubscribe: (state, action: PayloadAction<iActions.myTariff>) => {
            requestStart(state.unSubscribe);
        },
        _unSubscribeSuccess: (state) => {
            if (state.myTariff.data) {
                state.myTariff.data.plan.active = false;
            }
            requestSuccess(state.unSubscribe);
        },
        _unSubscribeError: (state) => {
            requestError(state.unSubscribe);
        },
        addUsers: (state, action: PayloadAction<iActions.addUsers>) => {
            requestStart(state.addUsers);
        },
        _addUsersSuccess: (state, action: PayloadAction<iActions._addUsersSuccess>) => {
            if (state.myTariff.data) {
                state.myTariff.data.users.overall = state.myTariff.data.users.overall + action.payload.addedCount;
                state.myTariff.data.next_pay.price = action.payload.newPrice;
            }
            requestSuccess(state.addUsers);
        },
        _addUsersError: (state) => {
            requestError(state.addUsers);
        },
        addPages: (state, action: PayloadAction<iActions.addPages>) => {
            requestStart(state.addPages);
        },
        _addPagesSuccess: (state, action: PayloadAction<iActions._addPagesSuccess>) => {
            if (state.myTariff.data) {
                state.myTariff.data.pages.overall = state.myTariff.data.pages.overall + action.payload.addedCount;
                state.myTariff.data.next_pay.price = action.payload.newPrice;
            }
            requestSuccess(state.addPages);
        },
        _addPagesError: (state) => {
            requestError(state.addPages);
        },
    },
});

export const Actions = Slice.actions;
export default Slice.reducer;
