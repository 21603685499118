import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Auth/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const UserVerify = function* (action: PayloadAction<iActions.userVerify>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield* call(Api.Auth.verify, payload);
        yield* put(Actions.Auth._userVerifySuccess());
        notification({
            type: "info",
            message: "Ваш аккаунт верифицирован"
        });
    } catch (error) {
        yield* put(Actions.Auth._userVerifyError());
        notification({
            type: "error",
            message: "При верификации аккаунта произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Auth.userVerify, UserVerify);
}
