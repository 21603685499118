import React from "react";
import {Tabs} from "antd";
import Profile from "./Profile/Profile";
import Tariff from "./Tariff/Tariff";
// TODO для обычных пользователей можно вырезать часть страниц или заменить их вьюшку (сделать дубль в доработками) на основе роли
const Content = () => {
    const items = [
        {
            key: "Управление подпиской",
            label: "Управление подпиской",
            children: <Tariff />
        },
        {
            key: "Команда",
            label: "Команда",
            children: "Команда"
        },
        {
            key: "Личный кабинет",
            label: "Личный кабинет",
            children: <Profile />
        },
    ];
    return <Profile />;

    return <Tabs items={items} />;
};

export default Content;
