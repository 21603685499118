import qs from "qs";
import {SberidSDKSuccessResult} from "@sberid/js-sdk";

const getAuthRoutes = (prefix:string = "") => {
    const lprefix = `${prefix}/auth`;
    return {
        login: `${lprefix}/login`,
        registration: `${lprefix}/registration`,
        recovery: `${lprefix}/recovery`,
        root: lprefix,
        reset: (
            token: string = ":token"
        ) => `${lprefix}/reset/${token}`,
        verify: (
            token: string = ":token"
        ) => `${lprefix}/verify/${token}`,
        sberIDAuth: (query?: SberidSDKSuccessResult) => {
            const queryStr = qs.stringify(query, {addQueryPrefix: true});
            return `/sberid_auth${queryStr}`;
        },
        sbiAuth: (query?: SberidSDKSuccessResult) => {
            const queryStr = qs.stringify(query, {addQueryPrefix: true});
            return `/sbi_auth${queryStr}`;
        },
    };
};

export default getAuthRoutes;
