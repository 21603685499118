import React from "react";
import {takeEvery, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@root/Redux/Auth/types";
import Cookies from "universal-cookie";
import {decode, jwtToken} from "@utils/JWT/decode";

const cookies = new Cookies();

const VendorAuth = function* (action: PayloadAction<iActions.vendorAuth>) {
    const {auth_code, state, redirect_uri, onSuccess, onError, vendor} = action.payload;
    try {
        const {data} = yield* call(Api.Auth.oauthAuth, 
            {
                auth_code,
                state,
                redirect_uri,
                vendor
            });
        if (!data) throw Error("Ошибка при обработке запроса");
        const decodedAccess: jwtToken = decode(data.access);
        yield call(
            {
                context: cookies,
                fn: cookies.set,
            },
            "access",
            data.access,
            {
                // TODO Пока нет SSL сертификата, убираю это
                // secure: true,
                expires: new Date(decodedAccess.exp * 1000),
                path: "/",
            }
        );
        yield* put(Actions.User.getUserInfo());
        yield* put(Actions.Auth._vendorAuthSuccess(data));
        onSuccess && onSuccess();
    } catch (error) {
        yield* put(Actions.Auth._vendorAuthError("Ошибка при авторизации по Сбер ID"));
        onError && onError();
    }
};

export default function* () {
    yield* takeEvery(Actions.Auth.vendorAuth, VendorAuth);
}
