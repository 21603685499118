import * as React from "react";

const Government = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z" fill="#FEFEFE" fillOpacity="0.2"/>
            <path d="M18.5939 11.3054C19.2577 10.9858 20.0288 10.9752 20.7011 11.2766L28.6318 14.8318C28.9677 14.9823 29.1838 15.3161 29.1838 15.6842C29.1838 16.2001 28.7656 16.6183 28.2497 16.6183H11.6934C11.1724 16.6183 10.75 16.1959 10.75 15.6749C10.75 15.3125 10.9576 14.9821 11.2841 14.8249L18.5939 11.3054ZM11.8462 15.9922L11.8466 15.992C11.8465 15.9921 11.8463 15.9921 11.8462 15.9922L11.7646 15.8227L11.8462 15.9922ZM28.2497 16.3227C28.6024 16.3227 28.8883 16.0368 28.8883 15.6842C28.8883 15.4326 28.7405 15.2044 28.5109 15.1015L21.1296 11.7927C20.1884 11.3707 19.109 11.3854 18.1796 11.8329L11.4124 15.0912C11.1882 15.1992 11.0456 15.426 11.0456 15.6749C11.0456 16.0327 11.3356 16.3227 11.6934 16.3227H28.2497Z" fill="#00D98B" stroke="#00D98B"/>
            <path d="M12.3553 29.25C11.4687 29.25 10.75 28.5312 10.75 27.6446C10.75 26.758 11.4687 26.0393 12.3553 26.0393H27.5785C28.4651 26.0393 29.1838 26.758 29.1838 27.6446C29.1838 28.5312 28.4651 29.25 27.5785 29.25H12.3553ZM12.3553 26.3349C11.632 26.3349 11.0456 26.9213 11.0456 27.6446C11.0456 28.368 11.632 28.9544 12.3553 28.9544H27.5785C28.3019 28.9544 28.8883 28.368 28.8883 27.6446C28.8883 26.9213 28.3019 26.3349 27.5785 26.3349H12.3553Z" fill="#00D98B" stroke="#00D98B"/>
            <path d="M12.5176 18.7382C12.5176 18.3805 12.8076 18.0905 13.1654 18.0905C13.5231 18.0905 13.8132 18.3805 13.8132 18.7382V23.9206C13.8132 24.2784 13.5231 24.5684 13.1654 24.5684C12.8076 24.5684 12.5176 24.2784 12.5176 23.9206V18.7382Z" fill="#00D98B"/>
            <path d="M26.1213 18.7382C26.1213 18.3805 26.4113 18.0905 26.7691 18.0905C27.1268 18.0905 27.4169 18.3805 27.4169 18.7382V23.9206C27.4169 24.2784 27.1268 24.5684 26.7691 24.5684C26.4113 24.5684 26.1213 24.2784 26.1213 23.9206V18.7382Z" fill="#00D98B"/>
            <path d="M17.0524 18.7382C17.0524 18.3805 17.3424 18.0905 17.7002 18.0905C18.0579 18.0905 18.348 18.3805 18.348 18.7382V23.9206C18.348 24.2784 18.0579 24.5684 17.7002 24.5684C17.3424 24.5684 17.0524 24.2784 17.0524 23.9206V18.7382Z" fill="#00D98B"/>
            <path d="M21.5861 18.7382C21.5861 18.3805 21.8761 18.0905 22.2339 18.0905C22.5916 18.0905 22.8816 18.3805 22.8816 18.7382V23.9206C22.8816 24.2784 22.5916 24.5684 22.2339 24.5684C21.8761 24.5684 21.5861 24.2784 21.5861 23.9206V18.7382Z" fill="#00D98B"/>
        </svg>
    );
};

export default Government;
