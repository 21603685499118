import Auth from "./AuthApi/Auth";
import User from "./UserApi/User";
import Projects from "./ProjectsApi/Projects";
import Project from "./ProjectApi/Project";
import Document from "./DocumentApi/Document";
import Anonymize from "./Anonymize/Anonymize";
import Common from "./CommonApi/Common";
import HTML from "./HTMLApi/HTML";
import Templates from "./Templates/Templates";
import Tariff from "./TariffApi/Tariff";

export default {
    Auth,
    User,
    Projects,
    Project,
    Document,
    Anonymize,
    Common,
    HTML,
    Templates,
    Tariff
};
