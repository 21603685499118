import React, {useEffect} from "react";
import {ButtonSberID as DefaultButtonSberID} from "@root/Components/Controls";
import styles from "./ButtonSberID.module.less";
import {useAppDispatch, useAppSelector, useNotification} from "@root/Hooks";
import {getAbsPath} from "@root/Utils/getAbsPath/getAbsPath";
import routes from "@root/Routes/Routes";
import {useHistory} from "react-router-dom";
import Actions from "@actions";
import {Form} from "antd";

const ButtonSberID = () => {
    const sberIDInfo = useAppSelector((state) => state.Auth.vendors.sber_id.data);
    const history = useHistory();
    const notification = useNotification();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(Actions.Auth.getVendorInfo({vendor: "sber_id"}));
        return () => {
            dispatch(Actions.Auth.resetVendorsInfo());
        };
    }, []);

    if (!sberIDInfo) return null;

    return (
        <Form.Item>
            <DefaultButtonSberID 
                config={{
                    baseUrl: sberIDInfo.authorizationUrl,
                    buttonProps: {
                        type: "default",
                        loader: false,
                    },
                    fastLogin: {
                        enable: true,
                        mode: "default",
                        onErrorDefaultMode: () => true
                    },
                    oidc: {
                        response_type: "code",
                        client_type: "PRIVATE",
                        client_id: sberIDInfo.client_id,
                        state: sberIDInfo.state,
                        redirect_uri: getAbsPath(routes.auth.sberIDAuth()),
                        scope: "openid mobile",
                        nonce: sberIDInfo.nonce,
                    },
                    personalization: true,
                    onSuccessCallback: (data) => {
                        if (data) {
                            history.push(routes.auth.sberIDAuth(data));
                        } else {
                            notification({
                                type: "error",
                                message: "Возникла ошибка при получении данных от Сбер ID, попробуйте позже"
                            });
                        }
                    },
                }}
                className={styles.sberButton}
            />
        </Form.Item>
    );
};

export default ButtonSberID;
