import React from "react";
import styles from "./Error.module.less";
import {Button} from "@root/Components/Controls";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import {WarningOutlined} from "@ant-design/icons";

const Error = () => {
    const history = useHistory();

    return (
        <div className={styles.wrapper}>
            <div className={styles.icon}>
                <WarningOutlined />
            </div>
            При авторизации произошла ошибка
            <div className={styles.button}>
                <Button
                    type="primary"
                    size="large"
                    onClick={() => history.push(routes.auth.login)}>
                    Вернуться на страницу авторизации
                </Button>
            </div>
        </div>
    );
};

export default Error;
