import React from "react";
import {takeEvery, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@root/Redux/Auth/types";

const GetVendorInfo = function* (action: PayloadAction<iActions.getVendorInfo>) {
    const {payload} = action;
    const {onSuccess, vendor} = payload;
    try {
        const {data} = yield* call(Api.Auth.getOauthInfo, {
            vendor
        });
        if (!data) throw Error("Ошибка при обработке запроса");
        yield* put(Actions.Auth._getVendorInfoSuccess({
            info: data,
            vendor
        }));
        onSuccess && onSuccess();
    } catch (error) {
        yield* put(Actions.Auth._getVendorInfoError(payload.vendor));
        
    }
};

export default function* () {
    yield* takeEvery(Actions.Auth.getVendorInfo, GetVendorInfo);
}
