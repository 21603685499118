import Title from "@root/Components/Title/Title";
import React from "react";
import styles from "./AnonymizeIntro.module.less";
import Success from "./Icons/Success";
import Loading from "./Icons/Loading";
import Redact from "./Icons/Redact";
import Img from "./Images/Man.svg";
import {Fade} from "react-reveal";
import {Link, animateScroll as scroll} from "react-scroll";
import Nbsp from "@root/Components/Nbsp/Nbsp";
import {Button} from "@root/Components/Controls";

const AnonymizeIntro = () => {
    
    return (
        <div
            className={styles.wrapper}
            id="AnonymizeIntro">
            <div className={styles.content}>
                <div className={styles.info}>
                    <div>
                        <Title
                            variant="h1"
                            className={styles.title}>
                            <span>
                                Обезличивание документов с
                                <Nbsp />
                                помощью искусственного интеллекта
                            </span>
                        </Title>
                        <div
                            className={styles.subtitle}>
                            Управляйте своими данными эффективно,
                            <br />
                            сохраняя доверие клиентов
                        </div>
                        <div
                            className={styles.buttons}>
                            <Button
                                size="large"
                                className={styles.demoButton}
                                type="primary">
                                Попробовать бесплатно
                            </Button>
                        </div>
                    </div>

                    <img 
                        src={Img} 
                        className={styles.imgMobile}
                    />
                </div>

                <div
                    className={styles.blocks}>
                    <Fade delay={600}>
                        <div
                            className={styles.mainBlock}>
                            <Success />
                            <div className={styles.blockText}>
                                Удобный и
                                <Nbsp />
                                простой интерфейс  
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom delay={600}>
                        <div
                            className={styles.subBlock}>
                            <Redact />
                            <div className={styles.blockText}>
                                Возможность ручной верификации
                            </div>
                        </div>
                    </Fade>
                </div>
                <img 
                    src={Img} 
                    className={styles.img}
                />
            </div>
        </div>
    );
};

export default AnonymizeIntro;
