import Title from "@root/Components/Title/Title";
import React from "react";
import Scheme from "./Images/Scheme.svg";
import MobileScheme from "./Images/MobileScheme.svg";
import styles from "./AnonymizeProcess.module.less";

const AnonymizeProcess = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Title
                    variant="h1"
                    className={styles.title}>
                Как выглядит процесс обезличивания данных
                </Title>
                <img 
                    src={Scheme}
                    className={styles.img}
                />
                <img 
                    src={MobileScheme}
                    className={styles.mobileImg}
                />
            </div>

        </div>
    );
};

export default AnonymizeProcess;
