import * as React from "react";

const Wallet = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z" fill="#FEFEFE" fillOpacity="0.2"/>
            <path d="M27.9773 12.5H12.0227C11.0437 12.5 10.25 13.2975 10.25 14.2812V24.9688C10.25 25.9525 11.0437 26.75 12.0227 26.75H27.9773C28.9563 26.75 29.75 25.9525 29.75 24.9688V14.2812C29.75 13.2975 28.9563 12.5 27.9773 12.5Z" stroke="#00D98B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.25 18.0983H29.75" stroke="#00D98B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Wallet;
