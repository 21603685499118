import React, {useEffect, useRef, useState} from "react";
import {SberidSDKProps, SberidSDK} from "@sberid/js-sdk";
import styles from "./ButtonSberID.module.less";
import classNames from "classnames";

type ButtonSberIDProps = {
    config: Omit<SberidSDKProps, "container">
    className?: string
}

const ButtonSberID = (props: ButtonSberIDProps) => {
    const [SDK, setSDK] = useState<SberidSDK | null>(null);
    const ButtonSberIDContainer = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!ButtonSberIDContainer.current) return; 
        if (SDK) return;
        const ButtonSDK = new SberidSDK({
            ...props.config,
            container: ButtonSberIDContainer.current,
        });
        setSDK(ButtonSDK);

    }, [ButtonSberIDContainer.current, SDK]);
    return (
        <div
            ref={ButtonSberIDContainer}
            className={classNames(styles.buttonSberID, props.className)}>
        </div>
    );
};

export default ButtonSberID;
