import React from "react";
import {CheckOutlined} from "@ant-design/icons";
import styles from "./Success.module.less";

const AuthSuccess = () => {
    return (
        <div className={styles.wrapper}>
            <span className={styles.icon}>
                <CheckOutlined />
            </span>
            Успешная авторизация!
        </div>
    );
};

export default AuthSuccess;
