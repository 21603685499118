import React, {useEffect, useMemo, useRef, useState} from "react";
import Actions from "@actions";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Konva from "konva";
import {KonvaEventObject} from "konva/lib/Node";
import {PDFDocumentProxy} from "pdfjs-dist";
import {Layer, Stage} from "react-konva";
import {PDFPageProxy} from "react-pdf";
import PageWrapper from "./Components/PageWrapper/PageWrapper";
import ShadowRect from "./Components/ShadowRect/ShadowRect";
import useGetScale from "./Hooks/useGetScale";
import useRenderCanvas from "./Hooks/useRenderCanvas";
import {ColoredPdfNodeForRender} from "@root/Types/AnonymizationPdf";
import ResizableRect from "./Components/ResizableRect/ResizableRect";
import PageLoading from "./Components/PageLoading/PageLoading";
import styles from "./PdfPage.module.less";
import useGetNodesForPrint from "./Hooks/useGetNodesForPrint";

type PdfPageProps = {
    pageIndex: number;
    url: string;
    result: PDFDocumentProxy | null;
};

const PdfPage = (props: PdfPageProps) => {
    const {pageIndex, url, result} = props;
    const changedColoredPdfNodes = useAppSelector((state) => state.Anonymize.changedColoredPdfNodesState);
    const selectedColoredNodeID = useAppSelector((state) => state.Anonymize.selectedColoredNodeID);
    const changedColoredPdfNodesStatus = useAppSelector((state) => state.Anonymize.changedColoredPdfNodesStatus);
    const layerDocRef = useRef<Konva.Layer>(null);
    const coloredPdf = useAppSelector((state) => state.Anonymize.coloredPdf);
    const coloredPdfInfo = useAppSelector((state) => state.Anonymize.coloredPdfInfo.data);
    if (!coloredPdfInfo) return null;
    const stageRef = useRef<Konva.Stage>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [page, setPage] = useState<PDFPageProxy | null>(null);
    const [selectVisible, setSelectVisible] = useState(false);
    const [selectBottomRightX, setSelectBottomRightX] = useState(0);
    const [selectBottomRightY, setSelectBottomRightY] = useState(0);
    const [selectTopLeftX, setSelectTopLeftX] = useState(0);
    const [selectTopLeftY, setSelectTopLeftY] = useState(0);

    const nodesForPrint = useGetNodesForPrint({
        pageIndex,
        deletedNodes: changedColoredPdfNodes.to_delete
    });

    const dispatch = useAppDispatch();

    const scale = useGetScale({
        currentPageWidth: width,
        originalWidth: coloredPdfInfo.pages_size[pageIndex + 1][0]
    });

    const rectSize = useMemo(() => {
        return {
            x: Math.min(selectTopLeftX, selectBottomRightX),
            y: Math.min(selectTopLeftY, selectBottomRightY),
            width: Math.abs(selectBottomRightX - selectTopLeftX),
            height: Math.abs(selectBottomRightY - selectTopLeftY),
        };
    }, [selectTopLeftX, selectBottomRightX, selectTopLeftY, selectBottomRightY]);

    const createNewColoredNode = (event: KonvaEventObject<PointerEvent>) => {
        if (!coloredPdf.editMode) return;
        event.evt.preventDefault();
        setSelectVisible(false);
        if (rectSize.height === 0 || rectSize.width === 0 || !scale) return;
        dispatch(
            Actions.Anonymize.addColoredNode({
                x1: Math.ceil(rectSize.x / scale),
                y1: Math.ceil(rectSize.y / scale),
                x2: Math.ceil((rectSize.x / scale) + (rectSize.width / scale)),
                y2: Math.ceil((rectSize.y / scale) + (rectSize.height / scale)),
                page_number: pageIndex + 1,
            })
        );
        setSelectTopLeftX(0);
        setSelectBottomRightX(0);
        setSelectTopLeftY(0);
        setSelectBottomRightY(0);
    };

    const startCreateNewColoredNode = (event: KonvaEventObject<PointerEvent>) => {
        if (!event.evt.shiftKey || !coloredPdf.editMode || !stageRef.current) return;
        dispatch(Actions.Anonymize.setSelectedColoredNodeID(null));
        setSelectVisible(true);
        const stage = stageRef.current;
        event.evt.preventDefault();
        const position = stage.getPointerPosition();
        if (!position) return;
        setSelectTopLeftX(position.x);
        setSelectBottomRightX(position.x);
        setSelectTopLeftY(position.y);
        setSelectBottomRightY(position.y);
    };

    const onDelete = (id: string) => dispatch(Actions.Anonymize.deleteColoredNode({id}));
    
    const selectColoredNodeClickOutside = () => dispatch(Actions.Anonymize.setSelectedColoredNodeID(null));

    const selectColoredNode = (id: string | null) => dispatch(Actions.Anonymize.setSelectedColoredNodeID(id));

    const updateColoredNode = (item: ColoredPdfNodeForRender) => {
        if (!scale) return;
        const updatedElement = {
            x1: Math.ceil(item.x / scale),
            y1: Math.ceil(item.y / scale),
            x2: Math.ceil((item.x / scale) + (item.width / scale)),
            y2: Math.ceil((item.y / scale) + (item.height / scale)),
            page_number: item.page_number,
            id: item.id
        };
        dispatch(Actions.Anonymize.updateColoredNode(updatedElement));
        selectColoredNode(`${updatedElement.x1},${updatedElement.x2},${updatedElement.y1},${updatedElement.y2},${updatedElement.page_number}`);
    };

    const onMouseMoveWhenCreateNewColoredNode = (event: KonvaEventObject<MouseEvent>) => {
        if (!coloredPdf.editMode || !stageRef.current || !selectVisible) return;
        const stage = stageRef.current;
        event.evt.preventDefault();
        const position = stage.getPointerPosition();
        if (!position) return;
        setSelectBottomRightX(position.x);
        setSelectBottomRightY(position.y);
    };

    useEffect(() => {
        // Получаем информацию о страницах
        if (!coloredPdf.file || !url || !result || !layerDocRef.current) return;
        result.getPage(pageIndex + 1).then((page) => {
            setPage(page as unknown as PDFPageProxy);
        });
    }, [coloredPdf.file, pageIndex]);

    useEffect(() => {
        dispatch(Actions.Anonymize.changeColoredPdfEditMode(false));
    }, []);

    useRenderCanvas({
        layerDocRef,
        page,
        setHeight,
        setWidth
    });

    return (
        <PageWrapper pageIndex={pageIndex}>
            <>
                <Stage
                    className={styles.pageStage}
                    onClick={selectColoredNodeClickOutside}
                    ref={stageRef}
                    width={width}
                    height={height}
                    onMouseDown={startCreateNewColoredNode}
                    onMouseLeave={createNewColoredNode}
                    onMouseUp={createNewColoredNode}
                    onMouseMove={onMouseMoveWhenCreateNewColoredNode}>
                    <Layer ref={layerDocRef}/>
                    <Layer>
                        {scale && nodesForPrint
                            .map((item, index) => (
                                <ResizableRect 
                                    editMode={coloredPdf.editMode}
                                    item={item}
                                    scale={scale || 1}
                                    stageRef={stageRef}
                                    key={index}
                                    selectColoredNode={selectColoredNode}
                                    selectedColoredNodeID={selectedColoredNodeID}
                                    onDelete={onDelete}
                                    updateColoredNode={updateColoredNode}/>
                            ))}
                        <ShadowRect 
                            rectSize={rectSize} 
                            visible={selectVisible}/>
                    </Layer>
                </Stage>
                {(changedColoredPdfNodesStatus.fetching) && <PageLoading/>}
            </>
        </PageWrapper>
    );
};

export default PdfPage;
