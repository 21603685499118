import * as React from "react";

const Box = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z" fill="#FEFEFE" fillOpacity="0.2"/>
            <path d="M14.5625 11L11.75 14.75V27.875C11.75 28.3723 11.9475 28.8492 12.2992 29.2008C12.6508 29.5525 13.1277 29.75 13.625 29.75H26.75C27.2473 29.75 27.7242 29.5525 28.0758 29.2008C28.4275 28.8492 28.625 28.3723 28.625 27.875V14.75L25.8125 11H14.5625Z" stroke="#00D98B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.75 14.7506H28.625" stroke="#00D98B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.9383 18.4998C23.9383 19.4943 23.5432 20.4481 22.8399 21.1514C22.1367 21.8547 21.1828 22.2498 20.1883 22.2498C19.1937 22.2498 18.2399 21.8547 17.5366 21.1514C16.8334 20.4481 16.4383 19.4943 16.4383 18.4998" stroke="#00D98B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Box;

