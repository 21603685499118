import React, {useEffect} from "react";
import styles from "./Verify.module.less";
import Actions from "@actions";
import {Button} from "@root/Components/Controls";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import routes from "@routes";
import {useParams} from "./Hooks/useParams";
import {useHistory} from "react-router-dom";
import Loading from "@root/Components/Loading/Loading";
import VerifyRequest from "./Content/VerifyRequest/VerifyRequest";
import {RequestShortState} from "@root/Utils/Redux/types";

const getContent = (verify: RequestShortState, redirect: () => void) => {
    if (verify.fetching || !verify) {
        return (
            <Loading>
                Подождите, происходит верификация аккаунта...
            </Loading>
        );
    } else if (verify.error && verify.errMsg === "Ссылка просрочена") {
        return (
            <VerifyRequest />
        );
    } else if (verify.error) {
        return (
            <span className={styles.verifyError}>
                При верификации произошла ошибка, попробуйте еще раз
            </span>
        );
    } else if (verify.loaded) {
        return (
            <span className={styles.verifySuccess}>
                Верификация произошла успешно, вы можете пройти на страницу авторизации
                <Button
                    size="large"
                    type="primary"
                    onClick={redirect}>
                    Авторизоваться
                </Button>
            </span>
        );
    }
};

const Verify = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const history = useHistory();
    const verify = useAppSelector((state) => state.Auth.verify);

    const redirectToLogin = () => {
        history.push(routes.auth.login);
    };

    useEffect(() => {
        dispatch(Actions.Auth.userVerify({
            token: params.token,
        }));
    }, []);

    return (
        <div className={styles.wrapper}>
            {getContent(verify, redirectToLogin)}
        </div>
    );
};

export default Verify;
