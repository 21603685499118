import Title from "@root/Components/Title/Title";
import React, {useState} from "react";
import styles from "./Tariffs.module.less";
import TariffCard from "@components/TariffCard/TariffCard";
import {Radio} from "antd";
import {useAppSelector} from "@root/Hooks";
import Hint from "@root/Components/Hint/Hint";

const Tariffs = () => {
    const [radioVariant, setRadioVariant] = useState<string>("corp");
    const tariffs = useAppSelector((state) => state.Common.tariffs);

    if (tariffs.error || !tariffs.data) return null;

    return (
        <div
            className={styles.wrapper}
            id="Tariffs">
            <div className={styles.content}>
                <Title
                    variant="h1"
                    className={styles.title}>
                    Тарифы
                </Title>
                <Radio.Group
                    className={styles.radio}
                    onChange={(event) => setRadioVariant(event.target.value)}
                    value={radioVariant} optionType="button">
                    <Radio.Button value={"corp"} className={styles.radioButton}>
                        Корпоративный
                    </Radio.Button>
                    <Radio.Button value={"pers"} className={styles.radioButton} disabled>
                        <span className={styles.radioDisabled}>
                            Личный
                            <Hint
                                title={"В данный момент доступны только корпоративные тарифы. Тарифы для физических лиц появятся совсем скоро – следите за обновлениями!"}
                                placement="right"/>
                        </span>
                    </Radio.Button>
                </Radio.Group>
                {radioVariant === "corp" && (
                    <div className={styles.corpTariffs}>
                        {tariffs.data.corporate.map((item) => (
                            <TariffCard key={item.title} {...item} className={styles.card}/>
                        ))}
                    </div>
                )}
                {radioVariant === "pers" && (
                    <div className={styles.persTariffs}>
                        {tariffs.data.personal.map((item) => (
                            <TariffCard key={item.title} {...item} className={styles.card}/>
                        ))}
                    </div>
                )}

            </div>

        </div>
    );
};

export default Tariffs;
