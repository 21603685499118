import Title from "@root/Components/Title/Title";
import React from "react";
import styles from "./HowUse.module.less";
import {Row, Col} from "antd";
import Web from "./Icons/Web";
import API from "./Icons/API";
import {Fade} from "react-reveal";

const HowUse = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Title
                    className={styles.title}
                    variant="h1">
                    Как можно использовать Обезличиватель?
                </Title>
                <div className={styles.desc}>Сервис доступен в двух опциях:</div>
                <div>
                    <Row
                        gutter={[30, 40]}
                        className={styles.blocks}>
                        <Col className={styles.col}>
                            <Fade bottom>
                                <div className={styles.block}>
                                    <Web />
                                    <Title
                                        className={styles.subtitle}
                                        variant="h2">
                                        Web-интерфейс
                                    </Title>
                                    <ul>
                                        <li>
                                            возможность <b>проверять и&nbsp;корректировать</b> результаты обезличивания;
                                        </li>
                                        <li>
                                            возможность работать с&nbsp;данными с&nbsp;<b>любого рабочего места</b>.
                                        </li>
                                    </ul>
                                </div>
                            </Fade>
                        </Col>
                        <Col className={styles.col}>
                            <Fade
                                bottom
                                delay={100}>
                                <div className={styles.block}>
                                    <API />
                                    <Title
                                        className={styles.subtitle}
                                        variant="h2">
                                        API
                                    </Title>
                                    <ul>
                                        <li>
                                            возможность автоматической <b>пакетной</b> или <b>потоковой</b> обработки;
                                        </li>
                                        <li>
                                            <b>минимизация доступа</b> сотрудников к&nbsp;данным;
                                        </li>
                                        <li>
                                            возможность <b>интеграции</b> с&nbsp;корпоративными системами Заказчика.
                                        </li>
                                    </ul>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default HowUse;
