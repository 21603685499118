import React from "react";
import styles from "./Footer.module.less";
import {Row, Col} from "antd";
import Title from "@root/Components/Title/Title";
import {Link, animateScroll as scroll} from "react-scroll";
import Logo from "./Images/Logo.svg";
import SberPravo from "./Images/SberPravo.svg";

const Footer = () => {
    return (
        <div
            id="Footer"
            className={styles.wrapper}>
            <div className={styles.content}>
                <Row className={styles.row}>
                    <Col className={styles.leftCol}>
                        <div className={styles.legalInfo}>
                            <img 
                                src={Logo} 
                                className={styles.img}
                            />
                            <img
                                src={SberPravo}
                                className={styles.pravoLogo}
                            />
                            <div className={styles.law}>
                                <div>
                                    ОГРН 1187746905004 ИНН 9705124940
                                </div>
                                <div>
                                    Адрес: 121170, г. Москва, ул. Поклонная,
                                    <br />
                                    д. 3, офис 209, этаж 2
                                </div>
                                <div>
                                    © ООО «СБЕР ЛИГАЛ». Все права защищены.
                                    <br />
                                    При копировании необходимо упоминание.
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col className={styles.rightCol}>
                        <div className={styles.links}>
                            <Link
                                className={styles.link}
                                to="AnonymizeIntro"
                                smooth={true}>
                                О продукте
                            </Link>
                            <Link
                                className={styles.link}
                                to="Advantages"
                                smooth={true}>
                                Преимущества
                            </Link>
                            <Link
                                className={styles.link}
                                to="HowWork"
                                smooth={true}>
                                Как это работает?
                            </Link>
                            <Link
                                className={styles.link}
                                to="Tariffs"
                                smooth={true}>
                                Тарифы
                            </Link>
                        </div>
                        <Title 
                            className={styles.title}
                            variant="h2">
                            Заинтересовал наш сервис?
                        </Title>
                        <div className={styles.desc}>
                            Свяжитесь с нами и мы ответим на все вопросы.
                        </div>
                        <div className={styles.contacts}>
                            <a href="tel:+74994041037">+ 7 (499) 404-10-37</a>
                            <a href="mailto:deepdocs@sberpravo.ru">
                                deepdocs@sberpravo.ru
                            </a>
                        </div>
                        <div className={styles.lawDown}>
                            <div>
                                ОГРН 1187746905004 ИНН 9705124940
                            </div>
                            <div>
                                Адрес: 121170, г. Москва, ул. Поклонная,
                                <br />
                                д. 3, офис 209, этаж 2
                            </div>
                            <div>
                                © ООО «СБЕР ЛИГАЛ». Все права защищены.
                                <br />
                                При копировании необходимо упоминание.
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Footer;
