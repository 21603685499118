import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@root/Redux/Tariff/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {delay} from "redux-saga/effects";

const mockData = {
    plan: {
        title: "Премиум",
        active: true,
    },
    next_pay: {
        date: "28 ноября",
        price: 2000,
    },
    pages: {
        overall: 1750,
        used: 25,
    },
    users: {
        overall: 5,
        active: 1,
    }
};

const MyTariff = function* (action: PayloadAction<iActions.myTariff>) {
    const {onError, onSuccess} = action.payload;
    try {
        yield delay(1000); // УДАЛИТЬ 
        // const {data} = yield* call(Api.Tariff.myTariff);
        onSuccess && onSuccess();
        // yield* put(Actions.Tariff._mySubscriptionSuccess(data));
        yield* put(Actions.Tariff._myTariffSuccess(mockData));
    } catch (error) {
        onError && onError();
        yield* put(Actions.Tariff._myTariffError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.myTariff, MyTariff);
}
