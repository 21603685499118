import rootSagaCreator from "../rootSagaCreator";
import UserLoginSaga from "./UserLoginSaga";
import UserRecoverySaga from "./UserRecoverySaga";
import UserRegistrationSaga from "./UserRegistrationSaga";
import UserResetPassword from "./UserResetPassword";
import UserLogoutSaga from "./UserLogoutSaga";
import UserVerifySaga from "./UserVerifySaga";
import UserVerifyRequest from "./UserVerifyRequestSaga";
import GetVendorInfoSaga from "./GetVendorInfoSaga";
import VendorAuthSaga from "./VendorAuthSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        UserLoginSaga,
        UserRecoverySaga,
        UserRegistrationSaga,
        UserResetPassword,
        UserLogoutSaga,
        UserVerifySaga,
        UserVerifyRequest,
        GetVendorInfoSaga,
        VendorAuthSaga
    ], "AUTH");
}
