import Auth from "../../Auth";
import Cookies from "universal-cookie";
import routes from "@root/Routes/Routes";
import isRouteMatch from "@root/Utils/isRouteMatch/isRouteMatch";

const cookies = new Cookies();

let refreshTokenRequest: Promise<void> | null = null;

export const refreshToken = async () => {
    const accessToken = cookies.get("access_token");

    if (
        window.location.pathname === routes.auth.login ||
        window.location.pathname === routes.auth.registration ||
        window.location.pathname === routes.auth.recovery || 
        isRouteMatch(routes.auth.reset()) ||
        isRouteMatch(routes.auth.verify()) ||
        isRouteMatch(routes.auth.sberIDAuth()) ||
        isRouteMatch(routes.auth.sbiAuth()) ||
        window.location.pathname === routes.auth.root ||
        window.location.pathname === "/"
    ) return;
    if (accessToken === undefined) {
        if (refreshTokenRequest === null) refreshTokenRequest = Auth.refresh();
        await refreshTokenRequest;
        if (typeof refreshTokenRequest === "object") refreshTokenRequest = null;
    }
    
};

export default refreshToken;
