import React from "react";
import styles from "./StateInputNumber.module.less";
import {Button, Input} from "@root/Components/Controls";
import Minus from "./Icons/Minus";
import Plus from "./Icons/Plus";
import {iButtonProps} from "@root/Components/Controls/ButtonIcon/ButtonIcon";
import {InputProps} from "antd";
import classNames from "classnames";

type ButtonProps = Omit<iButtonProps, "icon">

type StateInputNumberProps = {
    className?: string,
    minusButtonProps?: ButtonProps
    inputProps?: Omit<InputProps, "type">
    plusButtonProps?: ButtonProps
}

const StateInputNumber = (props: StateInputNumberProps) => {
    const {minusButtonProps, inputProps, plusButtonProps, className} = props;
    return (
        <div className={classNames(styles.customInput, className)}>
            <Button 
                icon={<Minus />}
                {...minusButtonProps}/>
            <Input 
                type="number"
                {...inputProps}/>
            <Button 
                icon={<Plus />}
                {...plusButtonProps}/>
        </div>
    );
};

export default StateInputNumber;
