import Title from "@root/Components/Title/Title";
import React from "react";
import ManWithLaptop from "./Images/ManWithLaptop.svg";
import ManWithDoc from "./Images/ManWithDoc.svg";
import PC from "./Images/PC.svg";
import styles from "./Advantages.module.less";
import {Fade} from "react-reveal";

const Advantages = () => {
    return (
        <div
            className={styles.wrapper}
            id="Advantages">
            <div className={styles.content}> 
                <div className={styles.mainBlock}>
                    <Title
                        className={styles.title}
                        variant="h1">
                    Преимущества обезличивателя
                    </Title>
                    <div className={styles.mainDesc}>
                        DeepDocs преобразует чувствительные данные в&nbsp;обезличенные, снимая ограничения на&nbsp;их&nbsp;использование. Сервис позволяет скрывать текст в&nbsp;документах популярных форматов (PDF и&nbsp;Word), а&nbsp;также в&nbsp;изображениях.
                    </div>
                    <div className={styles.subBlock}>

                        <div className={styles.textBlock}>
                            <Title
                                className={styles.subtitle}
                                variant="h2">
                                Соответствие закону
                            </Title>
                            <div className={styles.subDesc}>
                                Удовлетворяет требованиям 152&nbsp;ФЗ &laquo;О&nbsp;персональных
                                данных&raquo;, Постановления Правительства &#8470;&nbsp;996,
                                а&nbsp;также ВНД компаний.
                            </div>
                        </div>
                        <Fade left>
                            <img 
                                src={ManWithLaptop} 
                                className={styles.img}
                            />
                        </Fade>
                    </div>
                </div>
                <div className={styles.subBlock}>
                    <div className={styles.textBlock}>
                        <Title
                            className={styles.subtitle}
                            variant="h2">
                            Дообучение моделей
                        </Title>
                        <div className={styles.subDesc}>
                            Обезличенные данные можно использовать для обучения моделей.
                        </div>
                    </div>
                    <Fade right>
                        <img 
                            src={PC} 
                            className={styles.img}
                        />
                    </Fade>
                </div>
                <div className={styles.subBlock}>
                    <div className={styles.textBlock}>
                        <Title
                            className={styles.subtitle}
                            variant="h2">
                            Выбор замен чувствительных данных
                        </Title>
                        <div className={styles.subDesc}>
                            Позволяет выбирать стратегию замены под задачи
                            клиента: замена из&nbsp;словаря или замена константами,
                            выбор замены дат из&nbsp;определенного диапазона,
                            и&nbsp;другие стратегии.
                        </div>
                    </div>
                    <Fade left>
                        <img 
                            src={ManWithDoc} 
                            className={styles.img}
                        />
                    </Fade>
                </div>
            </div>

        </div>
    );
};

export default Advantages;
