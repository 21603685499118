import React from "react";
import {iQueries} from "./types";
import useQueries from "@root/Hooks/useQueries/useQueries";
import SberIDExchange from "./Modules/SberIDExchange";
import {Api} from "@root/Types";

type SberAuthProps = {
    vendor: Api.Auth.VendorType;
}

const SberIDAuth = (props: SberAuthProps) => {
    const queries = useQueries<iQueries>();
    const {vendor} = props;

    return (
        <SberIDExchange data={queries} vendor={vendor} />
    );
};

export default SberIDAuth;
