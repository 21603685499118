import React from "react";
import LoadingComponent from "@components/Loading/Loading";
import styles from "./Loading.module.less";

const Loading = () => {
    return (
        <div className={styles.wrapper}>
            <LoadingComponent>
                Загрузка данных...
            </LoadingComponent>
        </div>
    );
};

export default Loading;
