import React, {useEffect} from "react";
import styles from "./Login.module.less";
import {Form} from "antd";
import Title from "@root/Components/Title/Title";
import {Button, FormItem, Input} from "@root/Components/Controls";
import Actions from "@actions";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Auth} from "@root/Api/AuthApi/types";
import Navigation from "../../Modules/Navigation/Navigation";
import routes from "@routes";
import {useHistory} from "react-router-dom";
import useLastPathStorage from "@root/Hooks/useSaveLastPath/Hooks/useLastPathStorage/useLastPathStorage";
import isLastPathValid from "@root/Utils/LastPath/isLastPathValid";
import ButtonSberID from "../../Components/ButtonSberID/ButtonSberID";
import ButtonSbi from "../../Components/ButtonSbi/ButtonSbi";

const Login = () => {
    const [lastPath] = useLastPathStorage();
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const state = useAppSelector((state) => state.Auth.login);

    const redirect = () => {
        if (isLastPathValid({lastPath})) history.push(lastPath);
        else history.push(routes.lk.projects);
    };

    const onLogin = (values: Auth.iLogin) => {
        dispatch(Actions.Auth.userLogin({
            ...values,
            redirect
        }));
    };
    
    return (
        <div className={styles.wrapper}>
            <Title
                variant="h1" 
                className={styles.title}>
                Авторизация
            </Title>
            {state.error && (
                <div className={styles.errorText}>{state.errMsg}</div>
            )}
            <Form
                form={form}
                name="Login"
                layout="vertical"
                onFinish={onLogin}>
                <FormItem
                    name="email"
                    className={styles.item}
                    label="E-mail"
                    rules={[
                        {
                            required: true,
                            message: "Это поле не может быть пустым."
                        },
                        {
                            type: "email",
                            message: "E-mail не подходит",
                        }]}>
                    <Input
                        allowClear
                        className={styles.input}
                        placeholder="Введите ваш email адрес" 
                        disabled={state.fetching}
                    />
                </FormItem>
                <FormItem
                    name="password"
                    label="Пароль"
                    required>
                    <Input.Password
                        disabled={state.fetching}
                        type="password"
                        className={styles.input}
                        placeholder="Пароль"
                    />
                </FormItem>
                <div className={styles.controls}>
                    <FormItem>
                        <Button
                            type="link">
                            <Navigation route={routes.auth.recovery}>
                                Забыли пароль?
                            </Navigation>
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button
                            size="large"
                            type="primary"
                            loading={state.fetching}
                            htmlType="submit"
                            className={styles.button}>
                            Войти
                        </Button>
                    </FormItem>
                    <ButtonSberID />
                    <ButtonSbi />
                    <FormItem>
                        <Button
                            type="link">
                            <Navigation route={routes.auth.registration}>
                                Еще не зарегистрированы?
                            </Navigation>
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
        
    );
};

export default Login;
