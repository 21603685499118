import Title from "@root/Components/Title/Title";
import React from "react";
import styles from "./TariffCard.module.less";
import {Button} from "@root/Components/Controls";
import classnames from "classnames";
import Cross from "./Icons/Cross.svg";
import Tick from "./Icons/Tick.svg";
import {Tariffs} from "@types";

type CardProps = Tariffs.Tariff & {
    className: string,
}

const TariffCard = (props: CardProps) => {
    const {title, type, users_limit, new_price, old_price, benefits, className} = props;

    const onPay = () => {
        console.log("Pay");
    };

    const onRequest = () => {
        console.log("Request");
    };

    return (
        <div className={classnames(className, styles.wrapper)}>
            <Title
                variant="h2"
                className={styles.title}>
                {title}
            </Title>
            <div className={styles.info}>
                <div className={styles.type}>{type}</div>
                {users_limit && (<div className={styles.users}>до {users_limit} пользователей</div>)}
                <div className={styles.price}>
                    {old_price && (<span className={styles.oldPrice}>{old_price} &#8381;</span>)}
                    {new_price && (<span className={styles.newPrice}>{new_price} &#8381;</span>)}
                    {new_price && (<span className={styles.priceMonth}>/мес</span>)}
                </div>
            </div>

            <Button
                type="primary"
                size="large"
                onClick={title === "On-premise" ? onRequest : onPay}
                className={styles.button}>
                {title === "On Premise" ? "Запросить" : "Оплатить"}
            </Button>
            <div className={styles.divider}/>
            <div className={styles.options}>
                {benefits.map((item) => (
                    <div key={item.name} className={styles.optionBlock}>
                        <img
                            width={16}
                            height={16}
                            src={item.is_positive ? Tick : Cross} 
                            className={styles.img}
                        />
                        <span className={styles.option}>
                            {item.name}
                        </span>
                    </div>

                ))}
            </div>
        </div>
    );
};

export default TariffCard;
