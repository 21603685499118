import React from "react";
import styles from "./AllTariffs.module.less";

const AllTariffs = () => {
    return (
        <div className={styles.wrapper}>
            <h1>
                Тарифы
            </h1>
            {/* Использовать тарифы Саши К. */}
        </div>
    );
};

export default AllTariffs;
