import React, {useEffect} from "react";
import {Layout} from "antd";
import Advantages from "./Modules/Advantages/Advantages";
import AnonymizeIntro from "./Modules/AnonymizeIntro/AnonymizeIntro";
import AnonymizeProcess from "./Modules/AnonymizeProcess/AnonymizeProcess";
import BusinessGoals from "./Modules/BusinessGoals/BusinessGoals";
import FooterElement from "./Modules/Footer/Footer";
import HeaderElement from "./Modules/Header/Header";
import HowUse from "./Modules/HowUse/HowUse";
import HowWork from "./Modules/HowWork/HowWork";
import styles from "./Landing.module.less";
import Tariffs from "./Modules/Tariffs/Tariffs";
import {useAppDispatch} from "@root/Hooks";
import Actions from "@actions";

const {Header, Footer, Content} = Layout;

const Landing = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(Actions.Common.getTariffs());
    }, []);

    return (
        <div className={styles.wrapper}>
            <Layout
                className={styles.layout}>
                <Header
                    className={styles.header}>
                    <HeaderElement />
                </Header>
                <Content>
                    <AnonymizeIntro />
                    <Advantages />
                    <HowWork />
                    <BusinessGoals />
                    <AnonymizeProcess />
                    <Tariffs />
                    <HowUse />
                </Content>
                <Footer
                    className={styles.footer}>
                    <FooterElement />
                </Footer>
            </Layout>

        </div>
    );
};

export default Landing;
