import Title from "@root/Components/Title/Title";
import React from "react";
import styles from "./BusinessGoals.module.less";
import {Row, Col} from "antd";
import Wallet from "./Icons/Wallet";
import Government from "./Icons/Government";
import Box from "./Icons/Box";
import Puzzle from "./Icons/Puzzle";
import Shield from "./Icons/Shield";
import Cross from "./Icons/Cross";
import Nbsp from "@root/Components/Nbsp/Nbsp";

const BusinessGoals = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Title
                    variant="h1"
                    className={styles.title}>
                    Какие бизнес задачи решает Обезличиватель?
                </Title>
                <div className={styles.desc}>
                    DeepDocs предлагает надежные решения для максимизации ценности анализа данных, сводя к
                    <Nbsp />
                    минимуму риски, связанные с
                    <Nbsp />
                    разглашением конфиденциальной информации.
                </div>
                <div>
                    <Row
                        className={styles.row}
                        gutter={[40, 40]}>
                        <Col
                            className={styles.col}>
                            <div className={styles.block}>
                                <Wallet />
                                <Title
                                    className={styles.subTitle}
                                    variant="h2">
                                Банки
                                </Title>
                                <ul>
                                    <li>
                                        обезличивание кредитных
                                        <br />
                                        историй и баз данных банка;
                                    </li>
                                    <li>
                                        удовлетворение регуляторным
                                        <br />
                                        требованиям и возможность
                                        <br />
                                        делиться данными.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col
                            className={styles.col}>
                        
                            <div className={styles.block}>
                                <Government />
                                <Title
                                    className={styles.subTitle}
                                    variant="h2">
                                Госсектор
                                </Title>
                                <ul>
                                    <li>
                                        обезличивание персональных
                                        <br />
                                        данных граждан (заявления,
                                        <br />
                                        обращения граждан);
                                    </li>
                                    <li>
                                        обработка и цифровизация
                                        <br />
                                        архивов данных.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col
                            className={styles.col}>
                            <div className={styles.block}>
                                <Box />
                                <Title
                                    className={styles.subTitle}
                                    variant="h2">
                                    Ритейл и промышленные компании
                                </Title>
                                <ul>
                                    <li>
                                        обезличивание коммуникаций
                                        <br />
                                        с клиентами: данные call-центров,
                                        <br />
                                        чат-ботов;
                                    </li>
                                    <li>
                                        обработка отзывов о компании,
                                        <br />
                                        услугах, товарах.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col
                            className={styles.col}>
                            <div className={styles.block}>
                                <Puzzle />
                                <Title
                                    className={styles.subTitle}
                                    variant="h2">
                                    Интеграторы
                                </Title>
                                <ul>
                                    <li>
                                        обезличивание персональных
                                        <br />
                                        данных бизнес-партнеров;
                                    </li>
                                    <li>
                                        обеспечение передачи большего
                                        <br />
                                        объема данных для разработки
                                        <br />
                                        решения.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col
                            className={styles.col}>
                            <div className={styles.block}>
                                <Shield />
                                <Title
                                    className={styles.subTitle}
                                    variant="h2">
                                    Страховые компании
                                </Title>
                                <ul>
                                    <li>
                                        обезличивание персональных
                                        <br />
                                        данных в отчетах страховых
                                        <br />
                                        агентов;
                                    </li>
                                    <li>
                                        обезличивание страховых
                                        <br />
                                        договоров.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col
                            className={styles.col}>
                            <div className={styles.block}>
                                <Cross />
                                <Title
                                    className={styles.subTitle}
                                    variant="h2">
                                    Медицинские компании
                                </Title>
                                <ul>
                                    <li>
                                        обезличивание историй болезни,
                                        <br />
                                        медицинских рецептов и других
                                        <br />
                                        персональных данных пациентов;
                                    </li>
                                    <li>
                                        обезличивание результатов
                                        <br />
                                        анализов.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    );
};

export default BusinessGoals;
