import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {iActions, iState} from "./types";
import {getShortState, requestStart, requestSuccess, requestError, getFullState} from "@root/Utils/Redux/getRequestState";

const initialState:iState.Value = {
    registration: getShortState(),
    login: getShortState(),
    recovery: getShortState(),
    reset: getShortState(),
    verify: getShortState(),
    verifyRequest: getShortState(),
    auth: getFullState(),
    vendors: {
        sber_id: getFullState(),
        sber_business_id: getFullState()
    },
};

const Slice = createSlice({
    initialState,
    name: "Auth",
    reducers: {
        userLogin: (state, action: PayloadAction<iActions.userLogin>) => {
            requestStart(state.login);
        },
        userLogout: (state) => {
            return state;
        },
        _userLoginSuccess: (state) => {
            requestSuccess(state.login);
        },
        _userLoginError: (state, action: PayloadAction<iActions._userLoginError>) => {
            requestError(state.login, action.payload.message);
        },
        userRegistration: (state, action: PayloadAction<iActions.userRegistration>) => {
            requestStart(state.registration);
        },
        _userRegistrationSuccess: (state) => {
            requestSuccess(state.registration);
        },
        _userRegistrationError: (state, action: PayloadAction<iActions._userRegistrationError>) => {
            requestError(state.registration, action.payload.message);
        },
        userRecovery: (state, action: PayloadAction<iActions.userRecovery>) => {
            requestStart(state.recovery);
        },
        _userRecoverySuccess: (state) => {
            requestSuccess(state.recovery);
        },
        _userRecoveryError: (state) => {
            requestError(state.recovery);
        },
        userResetPassword: (state, action: PayloadAction<iActions.userResetPassword>) => {
            requestStart(state.reset);
        },
        _userResetPasswordSuccess: (state) => {
            requestSuccess(state.reset);
        },
        _userResetPasswordError: (state) => {
            requestError(state.reset);
        },
        userVerify: (state, action: PayloadAction<iActions.userVerify>) => {
            requestStart(state.verify);
        },
        _userVerifySuccess: (state) => {
            requestSuccess(state.verify);
        },
        _userVerifyError: (state) => {
            requestError(state.verify);
        },
        userVerifyRequest: (state, action: PayloadAction<iActions.userVerifyRequest>) => {
            requestStart(state.verifyRequest);
        },
        _userVerifyRequestSuccess: (state) => {
            requestSuccess(state.verifyRequest);
        },
        _userVerifyRequestError: (state) => {
            requestError(state.verifyRequest);
        },
        getVendorInfo: (state, action:PayloadAction<iActions.getVendorInfo>) => {
            const {vendor} = action.payload;
            requestStart(state.vendors[vendor]);
        },
        _getVendorInfoSuccess: (state, action:PayloadAction<iActions._getVendorInfoSuccess>) => {
            const {vendor, info} = action.payload;
            requestSuccess(
                state.vendors[vendor],
                info
            );
        },
        _getVendorInfoError: (state, action:PayloadAction<iActions._getVendorInfoError>) => {
            requestError(state.vendors[action.payload]);
        },
        resetVendorsInfo: (state) => {
            state.vendors = {...state.vendors};
        },
        vendorAuth: (state, action: PayloadAction<iActions.vendorAuth>) => {
            requestStart(state.auth);
        },
        _vendorAuthSuccess: (state, action: PayloadAction<iActions._vendorAuthSuccess>) => {
            requestSuccess(state.auth, action.payload);
        },
        _vendorAuthError: (state, action: PayloadAction<iActions._vendorAuthError>) => {
            requestError(state.auth, action.payload);
        },
    }
});

export const Actions = Slice.actions;
export default Slice.reducer;
