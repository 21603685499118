import React from "react";
import useTitle from "@root/Hooks/useTitle/useTitle";
import ProfileContent from "./Content/Profile";

const Profile = () => {
    useTitle("DeepDocs - Профиль");
    
    return <ProfileContent />;
};

export default Profile;
