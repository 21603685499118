import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Auth/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const UserVerifyRequest = function* (action: PayloadAction<iActions.userVerifyRequest>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield* call(Api.Auth.verifyRequest, payload);
        yield* put(Actions.Auth._userVerifyRequestSuccess());
        notification({
            type: "info",
            message: "На Вашу почту направлено письмо верификации вашей почты"
        });
    } catch (error) {
        yield* put(Actions.Auth._userVerifyRequestError());
        notification({
            type: "error",
            message: "Во время запроса верификации почты произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Auth.userVerifyRequest, UserVerifyRequest);
}
