import Title from "@root/Components/Title/Title";
import {Button} from "@root/Components/Controls";
import {Row, Col, Drawer} from "antd";
import React, {useEffect, useState} from "react";
import {Link, animateScroll as scroll} from "react-scroll";
import Logo from "../../Images/Logo.svg";
import styles from "./Header.module.less";
import Menu from "./Icons/Menu";
import classnames from "classnames";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";

const Header = () => {
    const [headerClassName, setHeaderClassName] = useState(styles.transparentHeader);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const history = useHistory();

    const showDrawer = () => {
        setVisibleDrawer(true);
    };

    const closeDrawer = () => {
        setVisibleDrawer(false);
    };

    const onLoginClick = () => {
        history.push(routes.auth.login);
    };

    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            return setHeaderClassName(styles.transparentHeader);
        } else if (window.scrollY > 70) {
            return setHeaderClassName(styles.header);
        } 
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () =>
            window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    return (
        <div className={headerClassName}>
            <div className={styles.content}>
                <Row
                    className={styles.row}
                    justify="space-between">
                    <Col className={styles.logoCol}>
                        <img 
                            src={Logo} 
                            className={styles.img}
                        />
                    </Col>

                    <Col className={styles.menuCol}>
                        <div className={styles.links}>
                            <Link
                                className={styles.link}
                                to="AnonymizeIntro"
                                smooth={true}>
                                О продукте
                            </Link>
                            <Link
                                className={styles.link}
                                to="Advantages"
                                smooth={true}>
                                Преимущества
                            </Link>
                            <Link
                                className={styles.link}
                                to="HowWork"
                                smooth={true}>
                                Как это работает?
                            </Link>
                            <Link
                                className={styles.link}
                                to="Tariffs"
                                smooth={true}>
                                Тарифы
                            </Link>
                            <Link
                                className={styles.link}
                                to="Footer"
                                smooth={true}>
                                Контакты
                            </Link>
                        </div>
                        <div className={styles.buttons}>
                            <Button
                                type="default"
                                className={styles.strokeButton}
                                onClick={onLoginClick}
                                size="large">
                                Войти
                            </Button>
                            <Link
                                className={styles.link}
                                to="Tariffs"
                                smooth={true}>
                                <Button
                                    type="primary" size="large">
                                    Подключить
                                </Button>
                            </Link>
                        </div>
                        <Button
                            onClick={showDrawer}
                            className={styles.menuButton}
                            type="link">
                            <Menu />
                        </Button>
                    </Col>
                </Row>
            </div>
            <Drawer
                placement="right"
                onClose={closeDrawer}
                className={styles.drawer}
                width={"100%"}
                extra={
                    <img 
                        src={Logo} 
                        className={styles.img}
                    />
                }
                open={visibleDrawer}>
                <div
                    className={styles.drawerLinks}>
                    <Link
                        onClick={closeDrawer}
                        className={styles.link}
                        to="AnonymizeIntro"
                        smooth={true}>
                        О продукте
                    </Link>
                    <Link
                        onClick={closeDrawer}
                        className={styles.link}
                        to="Advantages"
                        smooth={true}>
                        Преимущества
                    </Link>
                    <Link
                        onClick={closeDrawer}
                        className={styles.link}
                        to="HowWork"
                        smooth={true}>
                        Как это работает?
                    </Link>
                    <Link
                        onClick={closeDrawer}
                        className={styles.link}
                        to="Tariffs"
                        smooth={true}>
                        Тарифы
                    </Link>
                    <Link
                        onClick={closeDrawer}
                        className={styles.link}
                        to="Footer"
                        smooth={true}>
                        Контакты
                    </Link>
                    <div className={styles.drawerButtons}>
                        <Link
                            onClick={closeDrawer}
                            className={styles.link}
                            to="Tariffs"
                            smooth={true}>
                            <Button
                                className={styles.drawerButton}
                                type="primary"
                                size="large">
                                Подключить
                            </Button>
                        </Link>
                        <Button
                            className={classnames(styles.drawerButton, styles.strokeButton)}
                            type="default"
                            onClick={onLoginClick}
                            size="large">
                            Войти
                        </Button>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default Header;
