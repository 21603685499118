import React from "react";

const SbiLogo = () => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11 0.520508C13.6169 0.520508 16.0208 1.43523 17.9094 2.96099L15.7699 4.53799C14.4113 3.60768 12.7684 3.06197 11 3.06197C6.33705 3.06197 2.54343 6.85819 2.54343 11.5216C2.54343 16.185 6.33705 19.979 11 19.979C15.6652 19.979 19.4588 16.185 19.4588 11.5216C19.4588 11.4459 19.4566 11.3702 19.4551 11.2944L21.8233 9.54889C21.9399 10.1874 22 10.8482 22 11.5216C22 17.5965 17.075 22.5205 11 22.5205C4.92576 22.5205 0 17.5965 0 11.5216C0 5.44454 4.92576 0.520508 11 0.520508ZM19.7032 4.79563C20.2236 5.46756 20.6683 6.19963 21.0232 6.98144L11.0001 14.3697L6.81232 11.7429V8.58368L11.0001 11.2105L19.7032 4.79563Z" fill="#397D8A"/>
        </svg>
    );
};

export default SbiLogo;
