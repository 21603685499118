import * as React from "react";

const Success = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill="#FEFEFE" fillOpacity="0.2"/>
            <path d="M15.1031 10.6001C12.627 10.6001 10.6201 12.6068 10.6201 15.0821V16.5951C10.6201 17.0093 10.9559 17.3451 11.3701 17.3451C11.7843 17.3451 12.1201 17.0093 12.1201 16.5951V15.0821C12.1201 13.4354 13.4552 12.1001 15.1031 12.1001H16.3391C16.7533 12.1001 17.0891 11.7643 17.0891 11.3501C17.0891 10.9359 16.7533 10.6001 16.3391 10.6001H15.1031Z" fill="#00CA81"/>
            <path d="M23.6919 10.6001C23.2777 10.6001 22.9419 10.9359 22.9419 11.3501C22.9419 11.7643 23.2777 12.1001 23.6919 12.1001H24.8969C26.5448 12.1001 27.8799 13.4354 27.8799 15.0821V16.5951C27.8799 17.0093 28.2157 17.3451 28.6299 17.3451C29.0441 17.3451 29.3799 17.0093 29.3799 16.5951V15.0821C29.3799 12.6068 27.373 10.6001 24.8969 10.6001H23.6919Z" fill="#00CA81"/>
            <path d="M23.5312 17.4697C23.2383 17.1768 22.7634 17.1768 22.4705 17.4697L19.0007 20.9395L17.5302 19.4696C17.2373 19.1767 16.7624 19.1768 16.4696 19.4698C16.1767 19.7627 16.1768 20.2376 16.4698 20.5304L18.4706 22.5304C18.7635 22.8232 19.2383 22.8232 19.5312 22.5303L23.5312 18.5303C23.8241 18.2374 23.8241 17.7626 23.5312 17.4697Z" fill="#00CA81"/>
            <path d="M11.3701 20.0546C10.9559 20.0546 10.6201 20.3904 10.6201 20.8046V24.8786C10.6201 27.3548 12.6269 29.3616 15.1031 29.3616H16.3391C16.7533 29.3616 17.0891 29.0258 17.0891 28.6116C17.0891 28.1974 16.7533 27.8616 16.3391 27.8616H15.1031C13.4553 27.8616 12.1201 26.5264 12.1201 24.8786V20.8046C12.1201 20.3904 11.7843 20.0546 11.3701 20.0546Z" fill="#00CA81"/>
            <path d="M28.6299 20.0546C28.2157 20.0546 27.8799 20.3904 27.8799 20.8046V24.8786C27.8799 26.5264 26.5447 27.8616 24.8969 27.8616H23.6919C23.2777 27.8616 22.9419 28.1974 22.9419 28.6116C22.9419 29.0258 23.2777 29.3616 23.6919 29.3616H24.8969C27.3731 29.3616 29.3799 27.3548 29.3799 24.8786V20.8046C29.3799 20.3904 29.0441 20.0546 28.6299 20.0546Z" fill="#00CA81"/>
        </svg>
    );
};

export default Success;
