const isRouteMatch = (route: string): boolean => {
    const currentPath = window.location.pathname;

    const patternSegments = route.split("/").filter(Boolean);
    const pathSegments = currentPath.split(/[/?]/).filter(Boolean);

    if (patternSegments.length !== pathSegments.length) {
        return false;
    }

    return patternSegments.every((segment, index) => {
        if (segment.startsWith(":")) {
            return true;
        }

        return segment === pathSegments[index];
    });
};

export default isRouteMatch;
