import Title from "@root/Components/Title/Title";
import React from "react";
import styles from "./HowWork.module.less";
import ReactCompareImage from "react-compare-image";
import Cover from "./Images/Cover.svg";
import Synthetic from "./Images/Synthetic.svg";
import Constant from "./Images/Constant.svg";
import Original from "./Images/Original.png";
import Marked from "./Images/Marked.png";
import Original_txt from "./Images/Original_txt.png";
import Synthetic_txt from "./Images/Synthetic_txt.png";
import Constant_txt from "./Images/Constant_txt.png";
import Nbsp from "@root/Components/Nbsp/Nbsp";

const HowWork = () => {
    return (
        <div
            className={styles.wrapper}
            id="HowWork">
            <div className={styles.content}>
                <div className={styles.titleBlock}>
                    <Title
                        className={styles.title}
                        variant="h1">
                        Как может работать наше обезличивание?
                    </Title>
                    <div className={styles.desc}>
                        DeepDocs может обезличивать информацию в документах тремя методами.
                    </div>
                </div>
                <div>
                    <div className={styles.strategyBlock}>
                        <div className={styles.descBlock}>
                            <div>
                                <img 
                                    src={Cover} 
                                    className={styles.img}
                                />
                            </div>
                            <Title
                                className={styles.descTitle}
                                variant="h2">
                                Закрашивание чувствительных данных
                            </Title>
                            <span className={styles.descText}>
                                DeepDocs закрашивает чувствительные данные с
                                <Nbsp />
                                возможностью редактирования внутри системы и
                                <Nbsp />
                                генерирует PDF-документы для передачи контрагентам
                            </span>
                        </div>
                        <div className={styles.compare}>
                            <ReactCompareImage
                                sliderLineColor="#858585"
                                leftImage={Original}
                                rightImage={Marked}/>
                        </div>

                    </div>
                    <div className={styles.strategyBlock}>

                        <div className={styles.descBlock}>
                            <div>
                                <img 
                                    src={Synthetic} 
                                    className={styles.img}
                                />
                            </div>
                            <Title
                                className={styles.descTitle}
                                variant="h2">
                            Замена синтетическими данными
                            </Title>
                            <span className={styles.descText}>
                            Для обезличивания вы&nbsp;можете использовать синтетические данные. Они выглядят точно так&nbsp;же, как ваши производственные данные, но&nbsp;без ущерба для конфиденциальности клиентов.
                            </span>
                        </div>
                        <div className={styles.compare}>
                            <ReactCompareImage
                                sliderLineColor="#858585"
                                leftImage={Original_txt}
                                rightImage={Synthetic_txt}/>
                        </div>
                    </div>
                    <div className={styles.strategyBlock}>
                        <div className={styles.descBlock}>
                            <div>
                                <img 
                                    src={Constant} 
                                    className={styles.img}
                                />
                            </div>
                            <Title
                                className={styles.descTitle}
                                variant="h2">
                                Замена константными значениями
                            </Title>
                            <span className={styles.descText}>
                                DeepDocs обнаруживает с
                                <Nbsp/>
                                высоким качеством более 20
                                <Nbsp/>
                                сущностей. Вы
                                <Nbsp/>
                                сможете производить обезличивание, используя собственные варианты замен чувствительных данных.
                            </span>
                        </div>
                        <div className={styles.compare}>
                            <ReactCompareImage
                                sliderLineColor="#858585"
                                leftImage={Original_txt}
                                rightImage={Constant_txt}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default HowWork;
