import Request from "../Request";
import Endpoints from "./endpoints";
import {Tariff} from "./types";

export default {
    myTariff: () => {
        return Request.get<Tariff.oMyTariff>(Endpoints.Tariff.my);
    },
    unSubscribe: () => {
        return Request.get<never>(Endpoints.Tariff.unSubscribe);
    },
    addUsers: (params: Tariff.iAddUsers) => {
        return Request.post<never>(Endpoints.Tariff.addUsers, params);
    },
    addPages: (params: Tariff.iAddUsers) => {
        return Request.post<never>(Endpoints.Tariff.addPages, params);
    }
};
