import Lk from "./Lk";
import Undefined from "./Undefined/Undefined";
import Auth from "./Auth/Auth";
import Landing from "./Landing/Landing";
import SberIDAuth from "./SberIDAuth/SberIDAuth";

export default {
    Lk,
    Undefined,
    Auth,
    Landing,
    SberIDAuth
};
