import React from "react";
import {useAppSelector} from "@root/Hooks";
import Loading from "../Content/Loading/Loading";
import Error from "../Content/Error/Error";
import AuthSuccess from "../Content/Success/Success";

const ContentController = () => {
    const state = useAppSelector((state) => state.Auth.auth);
    
    if (state.fetching) return <Loading />;
    if (state.error) return <Error />;
    if (state.data) return <AuthSuccess />;
    return null;
};

export default ContentController;
