import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {iActions} from "@root/Redux/Tariff/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {delay} from "redux-saga/effects";
import useNotification from "@root/Hooks/useNotification/useNotification";

const AddUsers = function* (action: PayloadAction<iActions.addUsers>) {
    const notification = useNotification();
    const {onError, onSuccess, addUsersCount, newPrice} = action.payload;
    try {
        yield delay(1000); // УДАЛИТЬ 
        // yield* call(Api.Tariff.addUsers, {addUsersCount});
        onSuccess && onSuccess();
        notification({
            type: "info",
            message: "Пользователи успешно добавлены"
        });
        yield* put(Actions.Tariff._addUsersSuccess({
            addedCount: addUsersCount,
            newPrice
        }));
    } catch (error) {
        notification({
            type: "error",
            message: "При добавлении пользователей произошла ошибка"
        });
        onError && onError();
        yield* put(Actions.Tariff._addUsersError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Tariff.addUsers, AddUsers);
}
