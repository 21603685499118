import rootSagaCreator from "../rootSagaCreator";
import AddPagesSaga from "./AddPagesSaga";
import AddUsersSaga from "./AddUsersSaga";
import MyTariffSaga from "./MyTariffSaga";
import unSubscribeSaga from "./unSubscribeSaga";

export default function* rootSaga() {
    yield rootSagaCreator([
        MyTariffSaga,
        unSubscribeSaga,
        AddUsersSaga,
        AddPagesSaga
    ], "TARIFF");
}
