export default {
    authorization: {
        auth: "/v1/auth/login",
        registration: "/v1/users/create-user",
        recovery: "/v1/users/password/reset-request",
        refresh: "/v1/auth/refresh-token",
        reset: "/v1/users/password/reset",
        verify: "/v1/users/email/verify",
        verifyRequest: "/v1/users/email/verify-request",
        logout: "/v1/auth/logout",
        getOauthInfo: "/v1/auth/oauth/{vendor}/authorize",
        OauthAuth: "/v1/auth/oauth/{vendor}/callback"
    },
};
