import React, {useEffect, useState} from "react";
import styles from "./ButtonSbi.module.less";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {getAbsPath} from "@root/Utils/getAbsPath/getAbsPath";
import routes from "@root/Routes/Routes";
import Actions from "@actions";
import {SberidUniversalLink} from "@sberid/js-sdk";
import {Button} from "@root/Components/Controls";
import {Form} from "antd";
import SbiLogo from "./Assets/SbiLogo";

const ButtonSbi = () => {
    const sbiInfo = useAppSelector((state) => state.Auth.vendors.sber_business_id.data);
    const dispatch = useAppDispatch();
    const [href, setHref] = useState<string>();

    useEffect(() => {
        dispatch(Actions.Auth.getVendorInfo({vendor: "sber_business_id"}));
        return () => {
            dispatch(Actions.Auth.resetVendorsInfo());
        };
    }, []);

    useEffect(() => {
        if (!sbiInfo) return;
        const oidc = {
            response_type: "code",
            client_type: "PRIVATE",
            client_id: sbiInfo.client_id,
            state: sbiInfo.state,
            redirect_uri: getAbsPath(routes.auth.sbiAuth()),
            scope: sbiInfo.scope,
            nonce: sbiInfo.nonce,
        };

        const link = new SberidUniversalLink({
            baseUrl:  sbiInfo.authorizationUrl,
            oidcParams: oidc,
            universalLinkUrl: sbiInfo.authorizationUrl
        });
        link.run(oidc)
            .then((result) => {
                const {link} = result;
                setHref(link);
            });
    }, [sbiInfo]);

    if (!sbiInfo) return null;

    return (
        <Form.Item className={styles.wrapper}>
            <a href={href}>
                <Button
                    size="large"
                    className={styles.btn}
                    icon={<SbiLogo />}>
                    <span className={styles.text}>
                        Войти по СберБизнес ID
                    </span>
                </Button>
            </a>
        </Form.Item>
    );
};

export default ButtonSbi;
