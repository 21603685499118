import * as React from "react";

const Cross = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z" fill="#FEFEFE" fillOpacity="0.2"/>
            <path d="M22.4375 29.75H17.5625C17.1317 29.7496 16.7186 29.5782 16.4139 29.2736C16.1093 28.9689 15.9379 28.5558 15.9375 28.125V24.0625H11.875C11.4442 24.0621 11.0311 23.8907 10.7264 23.5861C10.4218 23.2814 10.2504 22.8683 10.25 22.4375V17.5625C10.2504 17.1317 10.4218 16.7186 10.7264 16.4139C11.0311 16.1093 11.4442 15.9379 11.875 15.9375H15.9375V11.875C15.9379 11.4442 16.1093 11.0311 16.4139 10.7264C16.7186 10.4218 17.1317 10.2504 17.5625 10.25H22.4375C22.8683 10.2504 23.2814 10.4218 23.5861 10.7264C23.8907 11.0311 24.0621 11.4442 24.0625 11.875V15.9375H28.125C28.5558 15.9379 28.9689 16.1093 29.2736 16.4139C29.5782 16.7186 29.7496 17.1317 29.75 17.5625V22.4375C29.7494 22.8683 29.5779 23.2812 29.2733 23.5858C28.9687 23.8904 28.5558 24.0619 28.125 24.0625H24.0625V28.125C24.0619 28.5558 23.8904 28.9687 23.5858 29.2733C23.2812 29.5779 22.8683 29.7494 22.4375 29.75ZM11.875 17.5625V22.4375H17.5625V28.125H22.4375V22.4375H28.125V17.5625H22.4375V11.875H17.5625V17.5625H11.875Z" fill="#00D98B"/>
        </svg>

    );
};

export default Cross;
